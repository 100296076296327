/*Геттеры*/
import * as names from "store/names";
const getters = {
  [names.ADDRESS_DIALOG]: (state) => {
    return state.addressDialog;
  },

  [names.MAP_ADDRESS_DIALOG]: (state) => {
    return state.mapAddressDialog;
  },

  [names.MY_COORDINATES_LAT]: (state) => {
    return state.mapAddressCoordinatesLat;
  },

  [names.MY_COORDINATES_LONG]: (state) => {
    return state.mapAddressCoordinatesLong;
  },

  [names.MY_ADDRESS]: (state) => { return state.mapAddress;  },

};

export { getters };
