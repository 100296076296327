/*Мутации*/
import * as names from "store/names";

const mutations = {
  /*Сохраняет объект со списком городов*/
  [names.MUTATE_SAVE_OBJ_CITIES]: (state, payload) => {
    state.cities = payload;
  },
  /*Сохраняет сообщение о результатах выполнения запроса*/
  [names.MUTATE_SAVE_CITY_MESSAGE]: (state, payload) => {
    state.message = payload;
  },

  /*Сохраняет статус модального окна с выбором города*/
  [names.MUTATE_SAVE_CITY_MODAL_STATUS]: (state, payload) => {
    state.dialog = payload;
  },

  /*Сохраняет объект с полученным городом по координатам*/
  [names.MUTATE_SAVE_OBJ_CITY]: (state, payload) => {
    state.city = payload;
  },

  /*Сохраняет объект с выбранным городом*/
  [names.MUTATE_SAVE_SELECTED_CITY]: (state, payload) => {
    localStorage.setItem("selectedCity", JSON.stringify(payload.city));
  },

  /*Сохраняет объект с районами города*/
  [names.MUTATE_SAVE_CITY_PARTS]: (state, payload) => {
    state.cityParts = payload;
  },

  /*Сохраняет популярные специализации*/
  [names.MUTATE_SAVE_POPULAR_SPECIALIZATION]: (state, payload) => {
    state.popularSpec = payload;
  },

  /*Сохраняет статистику города*/
  [names.MUTATE_SAVE_CITY_COUNT]: (state, payload) => {
    state.cityCount = payload;
    state.cityNames = payload.cityInfo;
  },
};
export { mutations };
