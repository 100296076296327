import store from "store";
const drawer = {
  install(Vue) {
    Vue.prototype.$drawer = {
      showDrawer: function (activeState, type) {
        store.commit("drawer/MUTATE_SHOW_DRAWER", { activeState, type });
      },
      toggleState: function (activeState) {
        store.commit("drawer/MUTATE_TOGGLE_DRAWER", { activeState });
      },
    };
  },
};

export default drawer;
