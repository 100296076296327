import Api from "../services/service";
class CardService {
  api = new Api();
  /*Получение информации о враче по id*/
  getDoctorInfoById(payload) {
    return this.api.get("doctor/get-doctor-by-id", {
      doctor_id: payload.doctor_id,
    });
  }

  getSpecializationWithDoctorsForDepartment(payload) {
    return this.api.post("department/get-specializations-with-doctors", {
      departmentId: payload.departmentId,
    });
  }

  getDepartmentGallery(payload) {
    return this.api.post("department/get-gallery", {
      departmentId: payload.departmentId,
    });
  }
  getDepartmentServices(payload) {
    return this.api.post("department/get-services?page=" + payload.page + "&per-page=30", {
      departmentId: payload.departmentId,
      specializationId: payload.specializationId,
      page: payload.page,

    });
  }

  getDepartmentDoctors(payload) {
    return this.api.post("department/get-doctors", {
      departmentId: payload.departmentId,
      specializationId: payload.specializationId,
    });
  }

  getClinicDepartments(payload) {
    return this.api.post("department/get-departments", {
      clinicId: payload.clinicId,
    });
  }
}
export default new CardService();
