/*Мутации*/
import * as names from "store/names";

const mutations = {
  /*Сохраняет объект с отзывами*/
  [names.MUTATE_SAVE_OBJ_FEEDBACK]: (state, payload) => {
    state.objFeedback = payload;
  },

  /*Сохраняет объект с ошибкой*/
  [names.MUTATE_SAVE_FEEDBACK_MESSAGE]: (state, payload) => {
    state.message = payload;
  },
};
export { mutations };
