<template>
  <v-app id="app">
    <!--Прелоадер-->
    <preloader />

    <!--Шторка-->
    <side-drawer />

    <!--Уведомления-->
    <snackbar />

    <!--Индикатор интернет соединения-->
    <connection-indicator />

    <!--Главный компонент-->
    <MainComponent />
  </v-app>
</template>

<script>
import MainComponent from "./components/MainComponent";
import Snackbar from "./components/partials/Snackbar";
import SideDrawer from "./components/partials/SideDrawer";
import Preloader from "./components/partials/Preloader";
import ConnectionIndicator from "./components/partials/ConnectionIndicator";
// import * as names from "store/names";
// import { mapActions } from "vuex";
export default {
  name: "App",
  components: {
    ConnectionIndicator,
    Preloader,
    SideDrawer,
    MainComponent,
    Snackbar,
  },
  /*TODO: Для автополучения города по координатам*/
  // created() {
  //   this.$getLocation().then((coordinates) => {
  //     this.getCityByCoordinates({ lot: coordinates.lat, lat: coordinates.lng });
  //   });
  // },
  // methods: {
  //   ...mapActions({
  //     getCityByCoordinates: names.ACTION_GET_CITY_BY_COORDINATES,
  //   }),
  // },
};
</script>
<style lang="scss">
html,
body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  position: relative;
  &::-webkit-scrollbar {
    width: 5px;
    background-color: $grey40;
  }

  &::-webkit-scrollbar-track {
    background-color: $grey40;
    border-radius: $border-radius;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $deep-blue;
    border: 5px solid $deep-blue;
    border-radius: $border-radius;
  }
}
h1,
h2,
h3,
h4,
h6 {
  font-weight: 700;
  font-family: "Source Sans Pro", sans-serif;
}
h5 {
  font-weight: 600;
  font-family: "Source Sans Pro", sans-serif;
}
</style>
