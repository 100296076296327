/*Состояния*/
const state = () => ({
  cities: [],
  message: {},
  city: {},
  dialog: false,
  cityParts: [],
  cityCount: [],
  cityNames: [],
  popularSpec: null,
});
export { state };
