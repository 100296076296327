const fontsLoader = {
  install() {
    if (typeof document === "undefined") {
      return;
    }
    /*Шрифты загружаются после загрузки содержимого DOM*/
    document.addEventListener("DOMContentLoaded", () => {
      setTimeout(() => {
        const stylesheets = [
          "//fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;700;900&display=swap",
          "//fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap",
        ];

        stylesheets.forEach((href) => {
          const link = document.createElement("link");

          link.href = href;
          link.rel = "stylesheet";
          link.type = "text/css";

          /*Добавляем шрифты в Head*/
          document.head.appendChild(link);
        });
      }, 300);
    });
  },
};
export default fontsLoader;
