/*Геттеры*/
import * as names from "store/names";
const getters = {
  /*Геттер с ID врача*/
  [names.DOCTOR_ID]: (state) => {
    return state.doctorId;
  },

  /*Геттер с информацие о враче*/
  [names.OBJ_DOCTOR_CARD]: (state) => {
    return state.objDoctor;
  },

  /*Геттер с информацие об  услугах врача*/
  [names.OBJ_DOCTOR_CARD_SERVICES]: (state) => {
    return state.objDoctorServices;
  },

  /*Геттер с информацие об  специализациях  врача*/
  [names.OBJ_DOCTOR_CARD_SPECIALIZATION]: (state) => {
    return state.objDoctorSpecialization;
  },
  /*Геттер с информацие об  клиниках врача*/
  [names.OBJ_DOCTOR_CARD_DEPARTMENT]: (state) => {
    return state.objDoctorDepartment;
  },

  [names.OBJ_DOCTOR_CARD_SERVICE_PAGINATION]: (state) => {
    return state.objDoctorCardServicePagination;
  },
};

export { getters };
