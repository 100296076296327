/*Мутации*/
import * as names from "store/names";

const mutations = {
  /*Сохраняет инфу об успехе запроса*/
  [names.MUTATE_SAVE_ORDER_SUCCESS]: (state, payload) => {
    state.orderSuccess = payload;
  },

  /*Сохраняет инфу о текущем статусе модалки*/
  [names.MUTATE_SAVE_MODAL_ACTIVE]: (state, payload) => {
    state.modalActive = payload;
  },

  /*Сохраняет сообщение о результатах выполнения запроса*/
  [names.MUTATE_SAVE_ORDER_MESSAGE]: (state, payload) => {
    state.message = payload;
  },
};
export { mutations };
