import Api from "../services/service";

class BasketService {
  api = new Api();

  /*Получение корзины*/
  get() {
    return this.api.get("basket/get");
  }

  /*Добавление в корзину*/
  add(payload) {
    return this.api.post("basket/add", {
      service_id: payload.service_id,
    });
  }
}

export default new BasketService();
