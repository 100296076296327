import store from "../store";
// Показать прелоадер.
function showLoader(show, color, size, opacity) {
  store.commit("preloader/MUTATE_SHOW_PRELOADER", {
    show,
    color,
    size,
    opacity,
  });
}
export default {
  preloader: showLoader,
};
