<template>
  <v-dialog v-model="modalStatus" max-width="390">
    <v-card class="mx-auto">
      <v-card-title>Выберите ваш город</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-3">
        <v-autocomplete
          id="select-1"
          label="Выберите город"
          v-model="cityModel"
          :items="cities"
          item-text="title"
          item-value="id"
          return-object
          outlined
          dense
          required
        >
          <template slot="noItems">
            <div class="text-center overline">Начните вводить название...</div>
          </template>
        </v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="saveModalStatus(false)">Отменить</v-btn>
        <v-btn color="primary" text @click="changeCity">Выбрать</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as names from "store/names";

export default {
  name: "CityModal",
  data: () => ({
    dialog: false,
    city: {
      id: 167,
      title: "Волгоград",
    },
  }),
  created() {
    this.getCities();
  },
  computed: {
    ...mapGetters({
      cities: names.OBJ_CITIES,
      modalStatus: names.CITY_MODAL_STATUS,
      selectedCity: names.OBJ_SELECTED_CITY,
    }),
    cityModel: {
      get() {
        return this.selectedCity;
      },
      set(newVal) {
        return (this.city = newVal);
      },
    },
  },
  methods: {
    ...mapActions({
      getCities: names.ACTION_GET_CITIES,
      saveCity: names.ACTION_SAVE_SELECTED_CITY,
      saveModalStatus: names.ACTION_SAVE_CITY_MODAL_STATUS,
    }),
    async changeCity() {
      this.saveCity({ city: this.city });
      this.saveModalStatus(false);
    },
  },
};
</script>
