/*Мутации*/
import * as names from "store/names";

const mutations = {
  /*Сохраняет объект с новостями*/
  [names.MUTATE_SAVE_OBJ_NEWS]: (state, payload) => {
    state.news = payload;
  },

  /*Сохраняет объект с новостями*/
  [names.MUTATE_SAVE_OBJ_CURRENT_NEWS]: (state, payload) => {
    state.currentNews = payload;
  },

  /*Сохраняет объект с пагинацией*/
  [names.MUTATE_SAVE_OBJ_NEWS_PAGINATION]: (state, payload) => {
    state.newsPagination = payload;
  },

  /*Сохраняет сообщение о результатах выполнения запроса*/
  [names.MUTATE_SAVE_NEWS_MESSAGE]: (state, payload) => {
    state.message = payload;
  },
};
export { mutations };
