/*Экшены*/
import * as names from "store/names";
import SearchService from "@/services/SearchService";
import CardService from "@/services/CardService";

const actions = {
  /* Сохраняет ID врача*/
  async [names.ACTION_SAVE_DOCTOR_ID]({ commit }, payload) {
    commit(names.MUTATE_SAVE_DOCTOR_ID, payload);
  },

  /* Сохраняет объект с информацией о враче для карточки*/
  async [names.ACTION_SAVE_OBJ_DOCTOR_CARD]({ commit }, payload) {
    let response = await CardService.getDoctorInfoById(payload);
    if (response.success) {
      commit(names.MUTATE_SAVE_OBJ_DOCTOR_CARD, response.data);
      return "success";
    } else {
      commit(names.MUTATE_SAVE_SEARCH_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },

  async [names.ACTION_GET_DOCTOR_SERVICES]({ commit }, payload) {
    let response = await SearchService.mainSearchDoctorDetail(payload);
    if (response.success) {
      commit(names.MUTATE_SAVE_OBJ_DOCTOR_SERVICES, response.data);
      commit(
        names.MUTATE_SAVE_DOCTOR_CARD_SERVICE_PAGINATION,
        response.data.pagination
      );

      return "success";
    } else {
      commit(names.MUTATE_SAVE_DOCTOR_CARD_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },
};
export { actions };
