/*Экшены*/
import * as names from "store/names";
import NewsService from "../../../services/NewsService";

const actions = {
  /*Получение списка новостей*/
  async [names.ACTION_GET_ALL_NEWS]({ commit }, payload) {
    let response = await NewsService.getAll(payload);
    if (response.success) {
      commit(names.MUTATE_SAVE_OBJ_NEWS, response.data);
      commit(names.MUTATE_SAVE_OBJ_NEWS_PAGINATION, response.pagination);

      return "success";
    } else {
      commit(names.MUTATE_SAVE_NEWS_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },

  /*Получение конкретной новости*/
  async [names.ACTION_GET_CURRENT_NEWS]({ commit }, payload) {
    let response = await NewsService.get(payload);
    if (response.success) {
      commit(names.MUTATE_SAVE_OBJ_CURRENT_NEWS, response.data);
      return "success";
    } else {
      commit(names.MUTATE_SAVE_NEWS_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },
};
export { actions };
