/*Геттеры*/
import * as names from "store/names";

/*Геттеры*/
const getters = {
  [names.DRAWER_TYPE]: (state) => {
    return state.type;
  },

  /*Геттер с местом открытия быстрой записи*/
  [names.AFTER_DETAIL]: (state) => {
    return state.afterDetailState;
  },
};
export { getters };
