import Api from "../services/service";
class FeedbackService {
  api = new Api();

  getFeedback(payload) {
    return this.api.get("feedback/get-feedback", {
      type: payload.type,
      doctorId: payload.doctorId,
      departmentId: payload.departmentId,
    });
  }

  addFeedback(payload) {
    return this.api.post("feedback/add-feedback", {
      type: payload.type,
      text: payload.text,
      doctor_id: payload.doctor_id,
      department_id: payload.department_id,
      rating: payload.rating,
      email: payload.email,
      recaptcha_token: payload.recaptcha_token,
      phone: payload.phone,
      name: payload.name,
      doctor_department_id: payload.doctor_department_id,
    });
  }
}
export default new FeedbackService();
