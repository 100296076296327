/*Экшены*/
import * as names from "store/names";

const actions = {
  /* Сохраняет состояние модального окна */
  async [names.ACTION_SAVE_ADDRESS_DIALOG]({ commit }, payload) {
    commit(names.MUTATE_SAVE_ADDRESS_DIALOG, payload);
  },
  /* Сохраняет состояние модального окна для оказания адреса*/
  async [names.ACTION_SAVE_MAP_ADDRESS_DIALOG]({ commit }, payload) {
    commit(names.MUTATE_SAVE_MAP_ADDRESS_DIALOG, payload);
  },

  /* Сохраняет состояние указанных на карте координат*/
  async [names.ACTION_SAVE_MAP_COORDINATES]({ commit }, payload) {
    commit(names.MUTATE_SAVE_MAP_COORDINATES, payload);
  },

};
export { actions };
