/*Геттеры*/
import * as names from "store/names";

const getters = {
  /*Сообщение о результатах выполнения запроса*/
  [names.CITY_MESSAGE]: (state) => {
    return state.message;
  },

  /*Объект со списком городов*/
  [names.OBJ_CITIES]: (state) => {
    return state.cities;
  },

  /*Объект с полученным городом по координатам*/
  [names.OBJ_CITY]: (state) => {
    return state.city;
  },

  /*Состояние модалки выбора города*/
  [names.CITY_MODAL_STATUS]: (state) => {
    return state.dialog;
  },

  /*Объект с выбранным городом*/
  [names.OBJ_SELECTED_CITY]: (state) => {
    let defaultCity = { id: 167, title: "Волгоград" };
    let selectedCity = JSON.parse(localStorage.getItem("selectedCity"));

    if (selectedCity === null) {
      state.dialog = true;
      return defaultCity;
    } else return selectedCity;
  },

  /*Объект с районами города*/
  [names.OBJ_CITY_PARTS]: (state) => {
    return state.cityParts;
  },

  /*Объект со статистикой города*/
  [names.OBJ_CITY_COUNT]: (state) => {
    return state.cityCount;
  },

  /*Объект со статистикой города*/
  [names.OBJ_CITY_NAMES]: (state) => {
    return state.cityNames;
  },

  /*Объект с популярными специализациями*/
  [names.OBJ_POPULAR_SPECIALIZATION]: (state) => {
    return state.popularSpec;
  },
};
export { getters };
