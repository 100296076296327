/*Состояния*/
const state = () => ({
  /* Информация о враче */
  objDoctor: {},

  /*Специализации врача*/
  objDoctorSpecialization: [],

  /*Клиники в которых врач принимает*/
  objDoctorDepartment: [],

  /*ID Врача*/
  doctorId: [],

  /* Услуги врача */
  objDoctorServices: [],

  /*Сообщение о результатах выполнения запроса*/
  message: [],

  /*Объект с пагинацией */
  objDoctorCardServicePagination: [],

  /*Отзывы о враче*/
  objDoctorCardFeedback: [],
});
export { state };
