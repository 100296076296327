/*Геттеры*/
import * as names from "store/names";

const getters = {
  /*Сообщение о результатах выполнения запроса*/
  [names.BASKET_MESSAGE]: (state) => {
    return state.message;
  },

  /*Объект со статичной страницей*/
  [names.OBJ_BASKET]: (state) => {
    return state.basket;
  },
};
export { getters };
