/*Геттеры*/
import * as names from "store/names";

const getters = {
  /*Сообщение о результатах выполнения запроса*/
  [names.PAGE_MESSAGE]: (state) => {
    return state.message;
  },

  /*Объект со статичной страницей*/
  [names.OBJ_PAGE]: (state) => {
    return state.page;
  },
};
export { getters };
