import Api from "../services/service";

class PagesService {
  api = new Api();

  /*Получение статичных страниц*/
  get(payload) {
    return this.api.get("page/get", {
      slug: payload.slug,
    });
  }
}

export default new PagesService();
