/*Мутации*/
import * as names from "store/names";

const mutations = {
  /*Сохраняет объект с корзиной*/
  [names.MUTATE_SAVE_OBJ_BASKET]: (state, payload) => {
    state.basket = payload;
  },
  /*Сохраняет сообщение о результатах выполнения запроса*/
  [names.MUTATE_SAVE_BASKET_MESSAGE]: (state, payload) => {
    state.message = payload;
  },

  /*Сохраняет сообщение о результатах выполнения запроса добавления в корзину*/
  [names.MUTATE_ADD_BASKET_MESSAGE]: (state, payload) => {
    state.message = payload;
  },
};
export { mutations };
