import Api from "../services/service";

class CityService {
  api = new Api();

  /*Получение списка городов*/
  getAll() {
    return this.api.get("city/get", {});
  }

  /*Получение списка активных городов*/
  getActive() {
    return this.api.get("city/get-active", {});
  }

  /*Получение города по кооординатам*/
  getCityByCoordinates(payload) {
    return this.api.post("city/get-city-by-coordinates", {
      lat: payload.lat,
      lot: payload.lot,
    });
  }

  /*Получение районов города*/
  getCityParts(payload) {
    return this.api.get("city-part/get-city-part", {
      cityId: payload.cityId,
    });
  }

  /*Получение районов города*/
  getCityCount(payload) {
    return this.api.post("city/get-city-count", {
      cityId: payload,
    });
  }
}

export default new CityService();
