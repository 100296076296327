import { state } from "store/modules/search/states";
import { getters } from "store/modules/search/getters";
import { mutations } from "store/modules/search/mutations";
import { actions } from "store/modules/search/actions";

export default {
  state,
  getters,
  mutations,
  actions,
};
