<template>
  <section class="nav-menu">
    <router-link
          to="/"
          class="d-flex justify-center align-center"
    >
      <div class="nav-menu__item">
        <v-btn text color="primary">
          <img class="pr-2" src="~icons/home-blue.svg" alt="" />
          <span>Главная</span>
        </v-btn>
      </div>
    </router-link>
<!--    <router-link-->
<!--        to="/blog"-->
<!--        class="d-flex justify-center align-center"-->
<!--    >-->
<!--      <div class="nav-menu__item">-->
<!--        <v-btn text color="primary">-->
<!--          <img class="pr-2" src="~icons/blog.svg" alt="" />-->
<!--          <span>Новости</span>-->
<!--        </v-btn>-->
<!--      </div>-->
<!--    </router-link>-->
    <router-link
    to="/help"
    class="d-flex justify-center align-center"
    >
      <div class="nav-menu__item">
        <v-btn text color="primary">
          <img class="pr-2" src="~icons/information.svg" alt="" />
          <span>Помощь</span>
        </v-btn>
      </div>
    </router-link>
  </section>
</template>

<script>
export default {
  name: "NavMenuComponent",
}
</script>

<style lang="scss" scoped>
@import "~scss/helpers/helpers";
.nav-menu {
  font-family: $sanspro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  display: inline-flex;
  .router-link-exact-active {
    border-bottom: 2px solid $deep-blue;
    background: $blue-light-color;
    border-radius: 4px;
  }

}
.nav-menu__item {
  padding: 0px 2px;
}
a {
  &:hover {
    color: inherit !important;
  }
  img {
    width: 22px;
    height: 22px;
  }
}

</style>
