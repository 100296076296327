/*Экшены*/
import * as names from "store/names";
import CityService from "../../../services/CityService";
import SpecializationService from "../../../services/SpecializationService";

const actions = {
  /*Получение списка активных городов*/
  async [names.ACTION_GET_CITIES]({ commit }) {
    let response = await CityService.getActive();
    if (response.success) {
      commit(names.MUTATE_SAVE_OBJ_CITIES, response.data);
      return "success";
    } else {
      commit(names.MUTATE_SAVE_CITY_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },

  /*Получение города по координатам*/
  async [names.ACTION_GET_CITY_BY_COORDINATES]({ commit }, payload) {
    let response = await CityService.getCityByCoordinates(payload);
    if (response.success) {
      let coordinates = JSON.parse(localStorage.getItem("coordinates"));

      if (coordinates === null || coordinates !== response.data) {
        localStorage.setItem("coordinates", JSON.stringify(response.data[0]));
      } else {
        commit(names.MUTATE_SAVE_OBJ_CITY, coordinates);
      }
      return "success";
    } else {
      commit(names.MUTATE_SAVE_CITY_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },

  /*Получение районов города*/
  async [names.ACTION_GET_CITY_PARTS]({ commit }, payload) {
    let response = await CityService.getCityParts(payload);
    if (response.success) {
      commit(names.MUTATE_SAVE_CITY_PARTS, response.data);
      return "success";
    } else {
      commit(names.MUTATE_SAVE_CITY_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },

  /*Получение статистики города*/
  async [names.ACTION_GET_CITY_COUNT]({ commit }, payload) {
    let response = await CityService.getCityCount(payload);
    if (response.success) {
      commit(names.MUTATE_SAVE_CITY_COUNT, response.data);
      return "success";
    } else {
      commit(names.MUTATE_SAVE_CITY_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },

  /*Получение популярных специализаций по ID города*/
  async [names.ACTION_GET_POPULAR_SPECIALIZATION]({ commit }, payload) {
    let response = await SpecializationService.getPopularSpecialization(
      payload
    );
    if (response.success) {
      commit(names.MUTATE_SAVE_POPULAR_SPECIALIZATION, response.data);
      return "success";
    } else {
      commit(names.MUTATE_SAVE_CITY_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },

  /*Сохранение выбранного города*/
  async [names.ACTION_SAVE_SELECTED_CITY]({ commit }, payload) {
    commit(names.MUTATE_SAVE_SELECTED_CITY, payload);
    window.location.reload();
  },

  /*Сохранение статуса модального окна*/
  async [names.ACTION_SAVE_CITY_MODAL_STATUS]({ commit }, payload) {
    commit(names.MUTATE_SAVE_CITY_MODAL_STATUS, payload);
  },
};
export { actions };
