<template>
  <div>
    <v-overlay :value="!connected" :z-index="9999">
      <h2>
        <v-icon x-large>{{ icons.mdiCloseNetworkOutline }}</v-icon>
        Нет интернет соединения. Проверьте подключение или повторите позже...
        <v-icon x-large>{{ icons.mdiCloseNetworkOutline }}</v-icon>
      </h2>
    </v-overlay>
    <detected-speed>
      <v-banner elevation="6" sticky single-line slot="slow">
        <v-icon slot="icon" color="warning" size="36">
          {{ icons.mdiNetworkStrength2Alert }}
        </v-icon>
        Обнаружена низкая скорость интернет-соединения. Возможны задержки при
        попытке что-то найти.
      </v-banner>
    </detected-speed>
  </div>
</template>

<script>
import * as names from "../../store/names";
import DetectedSpeed from "vue-identify-network";
import { mdiCloseNetworkOutline, mdiNetworkStrength2Alert } from "@mdi/js";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "ConnectionIndicator",
  components: {
    DetectedSpeed,
  },
  data: () => ({
    icons: {
      mdiCloseNetworkOutline,
      mdiNetworkStrength2Alert,
    },
  }),
  created() {
    window.addEventListener("offline", () => {
      this.setConnected(false);
    });
    window.addEventListener("online", () => {
      this.setConnected(true);
    });
  },
  computed: {
    ...mapGetters({
      connectionStatus: names.CONNECTED,
    }),
    connected() {
      return this.connectionStatus;
    },
  },
  methods: {
    ...mapActions({
      setConnected: names.ACTION_SET_CONNECTED,
    }),
  },
};
</script>

<style scoped></style>
