import Api from "../services/service";

class NewsService {
  api = new Api();

  /*Получение всех новостей*/
  getAll(payload) {
    return this.api.get("new/all?page=" + payload.page + "&per-page=10", {});
  }

  /*Получение конкретной новости*/
  get(payload) {
    return this.api.get("new/get", {
      slug: payload.slug,
    });
  }
}

export default new NewsService();
