import { state } from "store/modules/news/states";
import { getters } from "store/modules/news/getters";
import { mutations } from "store/modules/news/mutations";
import { actions } from "store/modules/news/actions";

export default {
  state,
  getters,
  mutations,
  actions,
};
