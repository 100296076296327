/*Геттеры*/
import * as names from "store/names";

const getters = {
  /*Сообщение о результатах выполнения запроса*/
  [names.SEARCH_MESSAGE]: (state) => {
    return state.message;
  },

  /*Объект с результатами поиска в динамической подсказке (Врачи)*/
  [names.OBJ_SEARCH]: (state) => {
    return state.searchObj;
  },

  /*Объект с результатами поиска в динамической подсказке (Услуги)*/
  [names.OBJ_SEARCH_SERVICE]: (state) => {
    return state.searchServiceObj;
  },
  /*Объект с результатами поиска в динамической подсказке (Услуги)*/
  [names.OBJ_SEARCH_SERVICE_PAGINATION]: (state) => {
    return state.searchServicePagination;
  },

  /*Объект с результатами поиска в динамической подсказке (Клиники)*/
  [names.OBJ_SEARCH_CLINIC]: (state) => {
    return state.searchClinicObj;
  },

  /*Объект врачей приемами по врачам или специализациям во всех филиалах*/
  [names.OBJ_SEARCH_SERVICE_BY]: (state) => {
    return state.objServiceBy;
  },

  /*объект с уникальным контентом по специализации*/
  [names.OBJ_UNIQUE_CONTENT]: (state) => {
    return state.objUniqueContent;
  },
  /*Объект специализации врачей приемами по врачам во всех филиалах*/
  [names.OBJ_SEARCH_SERVICE_SPEC]: (state) => {
    return state.objSpecializationNames;
  },

  /*Объект пагинации с первичными приемами врачей во всех филиалах*/
  [names.OBJ_SEARCH_PAGINATION]: (state) => {
    return state.objPaginationSearch;
  },

  /*Объект сортировки с первичными приемами врачей во всех филиалах*/
  [names.OBJ_SEARCH_SORT_VALUES]: (state) => {
    return state.objSortValuesSearch;
  },
  /*Объект с выбранными значениями сортировки врачей во всех филиалах*/
  [names.SELECTED_SORT_VALUE]: (state) => {
    return state.selectedSortValue;
  },

  /*Объект со списком районов города*/
  [names.SELECTED_CITY_PARTS]: (state) => {
    return state.selectedCityParts;
  },

  /*Объект с выбранными значениями типа учреждения врачей во всех филиалах*/
  [names.SELECTED_TYPE_VALUE]: (state) => {
    return state.selectedTypeValue;
  },

  /*Объект со списком доступныз фильтров врачей во всех филиалах*/
  [names.AVAILABLE_FILTERS]: (state) => {
    return state.availableFilters;
  },

  /*Объект с выбранными значениями сортировки врачей по цене во всех филиалах*/
  [names.SELECTED_PRICE_VALUE]: (state) => {
    return state.selectedPrice;
  },

  /*Список возможных вариантов работы с детьми*/
  [names.OBJ_SEARCH_TYPE_VALUES]: (state) => {
    return state.objTypeValuesSearch;
  },

  /*Сохраняет укахзанные координаты для передачи в поиск (долгота)*/
  [names.SELECTED_MY_COORDINATES_LAT]: (state) => {
    return state.selectedMyCoordinatesLat;
  },

  /*Сохраняет укахзанные координаты для передачи в поиск (широта)*/
  [names.SELECTED_MY_COORDINATES_LONG]: (state) => {
    return state.selectedMyCoordinatesLong;
  },

  /*Максимальная цена услуги для фильтра*/
  [names.MAX_PRICE_SEARCH]: (state) => {
    return state.maxPriceSearch;
  },

  /*Минимальная цена услуги для фильтра*/
  [names.MIN_PRICE_SEARCH]: (state) => {
    return state.minPriceSearch;
  },

  /*Объект с подробным результатом услуг врача*/
  [names.OBJ_SEARCH_DOCTOR_DETAIL]: (state) => {
    return state.objDoctorDetail;
  },

  /*Объект пагинации с подробным результатом услуг врача*/
  [names.OBJ_SEARCH_DOCTOR_DETAIL_PAGINATION]: (state) => {
    return state.objDoctorDetailPagination;
  },

  /*Объект с краткой информацией о враче*/
  [names.OBJ_SEARCH_DOCTOR_DETAIL_INFO]: (state) => {
    return state.objDoctorDetailInfo;
  },

  /*Объект со списком врачей, оказывающих определенную услугу*/
  [names.OBJ_SEARCH_SERVICE_DETAIL]: (state) => {
    return state.objServiceDetail;
  },

  /*Объект с пагинацией в шторке с услугами*/
  [names.OBJ_SEARCH_SERVICE_DETAIL_PAGINATION]: (state) => {
    return state.objServiceDetailPagination;
  },

  /*Объект с информацией о клинике*/
  [names.OBJ_SEARCH_CLINIC_DETAIL]: (state) => {
    return state.objClinicDetail;
  },

  /*Геттер с поисковым значением*/
  [names.SEARCH_VALUE]: (state) => {
    return state.searchValue;
  },

  /*Геттер с поисковым значением*/
  [names.SEARCH_TYPE]: (state) => {
    return state.searchType;
  },

  /*Геттер с текстом в инпуте*/
  [names.SEARCH_PLACEHOLDER]: (state) => {
    return state.searchPlaceholder;
  },

  /*Геттер с ID филиала*/
  [names.DEPARTMENT_ID]: (state) => {
    return state.departmentId;
  },

  /*Геттер с информацией для быстрой записи к выбранному врачу*/
  [names.SELECTED_DOCTOR_INFO]: (state) => {
    return state.selectedDoctorInfo;
  },

  /*Геттер с информацией для быстрой записи на услугу в клинику*/
  [names.SELECTED_SERVICE_INFO]: (state) => {
    return state.selectedServiceInfo;
  },
};
export { getters };
