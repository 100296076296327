import { state } from "store/modules/map/states";
import { getters } from "store/modules/map/getters";
import { mutations } from "store/modules/map/mutations";
import { actions } from "store/modules/map/actions";

export default {
  state,
  getters,
  mutations,
  actions,
};
