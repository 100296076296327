<template>
  <div class="main-component">
    <HeaderComponent />
    <v-main>
      <transition name="fade-transition">
        <keep-alive>
          <router-view />
        </keep-alive>
      </transition>
    </v-main>
    <FooterComponent />
  </div>
</template>

<script>
import HeaderComponent from "./header/HeaderComponent";
import FooterComponent from "./footer/FooterComponent";
export default {
  name: "MainComponent",
  components: {
    HeaderComponent,
    FooterComponent,
  },
};
</script>

<style lang="scss" scoped>
.main-component {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100vw;
}
</style>
