import { state } from "store/modules/departmentCard/states";
import { getters } from "store/modules/departmentCard/getters";
import { mutations } from "store/modules/departmentCard/mutations";
import { actions } from "store/modules/departmentCard/actions";

export default {
  state,
  getters,
  mutations,
  actions,
};
