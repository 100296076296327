/*Геттеры*/
import * as names from "store/names";

const getters = {
  [names.RESULT_TEMPLATE_NAME]: (state) => {
    return state.resultTemplateName;
  },

  [names.ENTITY_NAME]: (state) => {
    return state.entityName;
  },

  [names.ENTITY_ID]: (state) => {
    return state.entityId;
  },

  [names.SEARCH_DATA]: (state) => {
    return state.searchData;
  },
};
export { getters };
