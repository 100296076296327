/*Геттеры*/
import * as names from "store/names";

const getters = {
  /*Сообщение о результатах выполнения запроса*/
  [names.NEWS_MESSAGE]: (state) => {
    return state.message;
  },

  /*Объект с новостями*/
  [names.OBJ_NEWS]: (state) => {
    return state.news;
  },

  /*Объект с конкретной новостью*/
  [names.OBJ_CURRENT_NEWS]: (state) => {
    return state.currentNews;
  },

  /*Объект с конкретной новостью*/
  [names.OBJ_NEWS_PAGINATION]: (state) => {
    return state.newsPagination;
  },
};
export { getters };
