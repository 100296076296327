/*Экшены*/
import * as names from "store/names";
import SearchService from "../../../services/SearchService";

const actions = {
  /*Поиск врачей/услуг/клиник*/
  async [names.ACTION_MAIN_SEARCH]({ commit }, payload) {
    let response = await SearchService.mainSearch(payload);
    if (response.success) {
      commit(names.MUTATE_SAVE_OBJ_SEARCH, response.data);
      return "success";
    } else {
      commit(names.MUTATE_SAVE_SEARCH_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },

  /*Очищает поиск врачей/услуг/клиник*/
  async [names.ACTION_CLEAR_MAIN_SEARCH]({ commit }) {
    commit(names.MUTATE_CLEAR_OBJ_SEARCH);
  },

  /*Получить первичные приемы врачей во всех филиалах*/
  async [names.ACTION_MAIN_SEARCH_DOCTOR_DEPARTMENT_FIRST_SERVICE_BY_DOCTOR_ID](
    { commit },
    payload
  ) {
    let response = await SearchService.mainSearchDoctorDepartmentFirstServiceByDoctorId(
      payload
    );
    if (response.success) {
      commit(names.MUTATE_SAVE_OBJ_SERVICE_BY, {
        data: response.data,
        update: payload.update,
      });
      return "success";
    } else {
      commit(names.MUTATE_SAVE_SEARCH_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },

  /*Очистить услуги по специализациям или врачам*/
  async [names.ACTION_CLEAR_OBJ_SERVICE_BY]({ commit }) {
    commit(names.MUTATE_CLEAR_OBJ_SERVICE_BY);
  },

  /*Получить приемы врачей по определенной специализации*/
  async [names.ACTION_MAIN_SEARCH_DOCTOR_DEPARTMENT_SERVICE_BY_SPECIALIZATION_ID](
    { commit },
    payload
  ) {
    let response = await SearchService.mainSearchDoctorDepartmentServiceBySpecializationId(
      payload
    );
    if (response.success) {
      commit(names.MUTATE_SAVE_OBJ_SERVICE_BY, {
        data: response.data,
        update: payload.update,
      });
      return "success";
    } else {
      commit(names.MUTATE_SAVE_SEARCH_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },

  /*Открытие шторки с подробным результатом услуг о докторе и краткой его информацией*/
  async [names.ACTION_MAIN_SEARCH_DOCTOR_DETAIL]({ commit }, payload) {
    let response = await SearchService.mainSearchDoctorDetail(payload);
    if (response.success) {
      commit(names.MUTATE_SAVE_OBJ_SEARCH_DOCTOR_DETAIL, response.data);
      commit(
        names.MUTATE_SAVE_OBJ_SEARCH_DOCTOR_DETAIL_PAGINATION,
        response.data.pagination
      );

      return "success";
    } else {
      commit(names.MUTATE_SAVE_SEARCH_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },
  /*Очистка шторки с подробным результатом услуг о докторе и краткой его информацией*/
  async [names.ACTION_MAIN_SEARCH_DOCTOR_DETAIL_CLEAR]({ commit }) {
    commit(names.MUTATE_CLEAR_OBJ_SEARCH_DOCTOR_DETAIL);
  },
  /*Очистка шторки с подробным результатом услуг о докторе и краткой его информацией*/
  async [names.ACTION_MAIN_SEARCH_SERVICE_DETAIL_CLEAR]({ commit }) {
    commit(names.MUTATE_CLEAR_OBJ_SEARCH_SERVICE_DETAIL);
  },

  /*Открытие шторки со списком врачей, оказывающих определенную услугу*/
  async [names.ACTION_MAIN_SEARCH_SERVICE_DETAIL]({ commit }, payload) {
    let response = await SearchService.mainSearchServiceDetail(payload);
    if (response.success) {
      commit(
        names.MUTATE_SAVE_OBJ_SEARCH_SERVICE_DETAIL,
        response.data.services
      );
      commit(
        names.MUTATE_SAVE_OBJ_SEARCH_SERVICE_DETAIL_PAGINATION,
        response.data.pagination
      );
      return "success";
    } else {
      commit(names.MUTATE_SAVE_SEARCH_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },

  /*Клик по динамической подсказке. Сущность service. Вкладка Услуга. Получить список услуг по филиалам с ценой*/
  async [names.ACTION_MAIN_SEARCH_SERVICE]({ commit }, payload) {
    let response = await SearchService.mainSearchService(payload);
    if (response.success) {
      commit(names.MUTATE_SAVE_OBJ_SERVICE_BY, {
        data: response.data,
        update: payload.update,
      });
      return "success";
    } else {
      commit(names.MUTATE_SAVE_SEARCH_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },

  /*Открытие шторки с подробным описанием филиала клиники*/
  async [names.ACTION_MAIN_SEARCH_CLINIC_DETAIL]({ commit }, payload) {
    let response = await SearchService.mainSearchClinicDetail(payload);
    if (response.success) {
      commit(names.MUTATE_SAVE_OBJ_SEARCH_CLINIC_DETAIL, response.data);
      commit(names.MUTATE_SAVE_DEPARTMENT_INFO, response.data);
      return "success";
    } else {
      commit(names.MUTATE_SAVE_SEARCH_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },

  /*Очистка шторки с подробным описанием филиала клиники*/
  async [names.ACTION_MAIN_SEARCH_CLINIC_DETAIL_CLEAR]({ commit }) {
    commit(names.MUTATE_CLEAR_OBJ_SEARCH_CLINIC_DETAIL);
  },

  /*Клик по динамической подсказке. Сущность clinic. Вкладка Клиника. Получить список филиалов клиники*/
  async [names.ACTION_MAIN_SEARCH_CLINIC]({ commit }, payload) {
    let response = await SearchService.mainSearchClinic(payload);
    if (response.success) {
      commit(names.MUTATE_SAVE_OBJ_SERVICE_BY, {
        data: response.data,
        update: payload.update,
      });

      return "success";
    } else {
      commit(names.MUTATE_SAVE_SEARCH_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },

  /*Экшн для передачи поисковых данных*/
  async [names.ACTION_TRANSMISSION_SEARCH_VALUE]({ commit }, payload) {
    commit(names.MUTATE_TRANSMISSION_SEARCH_VALUE, payload);
  },

  /*Экшн для передачи типа поиска*/
  async [names.ACTION_TRANSMISSION_SEARCH_TYPE]({ commit }, payload) {
    commit(names.MUTATE_TRANSMISSION_SEARCH_TYPE, payload);
  },

  /*Экшн для передачи текста в инпуте*/
  async [names.ACTION_TRANSMISSION_SEARCH_PLACEHOLDER]({ commit }, payload) {
    commit(names.MUTATE_TRANSMISSION_SEARCH_PLACEHOLDER, payload);
  },

  /*Экшн для очистки searchObj*/
  async [names.ACTION_CLEAR_SEARCH_OBJ]({ commit }, payload) {
    commit(names.MUTATE_SAVE_OBJ_SEARCH, payload);
  },

  /* Сохраняет ID филиала*/
  async [names.ACTION_SAVE_DEPARTMENT_ID]({ commit }, payload) {
    commit(names.MUTATE_SAVE_DEPARTMENT_ID, payload);
  },

  /* Сохраняет инфу для быстрой записи к выбранному доктору*/
  async [names.ACTION_SAVE_SELECTED_DOCTOR_INFO]({ commit }, payload) {
    commit(names.MUTATE_SAVE_SELECTED_DOCTOR_INFO, payload);
  },
  /* Сохраняет инфу для быстрой записи на выбранную услугу в клинику*/
  async [names.ACTION_SAVE_SELECTED_SERVICE_INFO]({ commit }, payload) {
    commit(names.MUTATE_SAVE_SELECTED_SERVICE_INFO, payload);
  },
  /* Сохраняет значения для сортировки*/
  async [names.ACTION_SAVE_SELECTED_SORT_VALUES]({ commit }, payload) {
    commit(names.MUTATE_SAVE_SELECTED_SORT_VALUES, payload);
  },
};
export { actions };
