import { state } from "store/modules/pages/states";
import { getters } from "store/modules/pages/getters";
import { mutations } from "store/modules/pages/mutations";
import { actions } from "store/modules/pages/actions";

export default {
  state,
  getters,
  mutations,
  actions,
};
