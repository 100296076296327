/*Геттеры*/
import * as names from "store/names";
const getters = {
  /*Геттер с ID клиники*/
  [names.DEPARTMENT_ID_CARD]: (state) => {
    return state.departmentId;
  },

  /*Геттер с информацией о клинике*/
  [names.OBJ_DEPARTMENT_INFO]: (state) => {
    return state.objDepartmentInfo;
  },

  /*Геттер с информацией о клинике*/
  [names.OBJ_CLINIC_DEPARTMENTS]: (state) => {
    return state.objClinicDepartments;
  },

  /*Геттер с галереей клиники*/
  [names.DEPARTMENT_GALLERY]: (state) => {
    return state.objGallery;
  },

  /*Геттер с услугами клиники*/
  [names.DEPARTMENT_SERVICES]: (state) => {
    return state.objDepartmentServices;
  },

  /*Геттер с инфой о выбранном докторе*/
  [names.DEPARTMENT_SELECTED_DOCTOR_INFO]: (state) => {
    return state.objSelectedDoctorInfo;
  } /*Геттер с врачами клиники*/,

  /*Геттер с услугами выбранного доктора*/
  [names.DEPARTMENT_SELECTED_DOCTOR_SERVICES]: (state) => {
    return state.objSelectedDoctorServices;
  },

  /*Геттер с врачами клиники*/
  [names.DEPARTMENT_DOCTORS]: (state) => {
    return state.objDepartmentDoctors;
  },

  /*Геттер со списком специализаций для услуг клиники*/
  [names.DEPARTMENT_SERVICES_SPECIALIZATIONS]: (state) => {
    return state.objDepartmentServicesSpecializations;
  },

  /*Геттер со списком специализаций для врачей клиники*/
  [names.DEPARTMENT_DOCTORS_SPECIALIZATIONS]: (state) => {
    return state.objDepartmentDoctorsSpecializations;
  },

  /*Объект с пагинацией*/
  [names.OBJ_DEPARTMENT_SERVICE_PAGINATION]: (state) => {
    return state.objServicesPagination;
  },

};

export { getters };
