/*Мутации*/
import * as names from "store/names";

const mutations = {
  [names.MUTATE_SHOW_MESSAGE]: (state, payload) => {
    state.content = payload.content;
    state.type = payload.type;
  },
};
export { mutations };
