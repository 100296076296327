/*Мутации*/
import * as names from "store/names";

const mutations = {
  /*Сохраняет ID врача*/
  [names.MUTATE_SAVE_DEPARTMENT_ID_CARD]: (state, payload) => {
    state.departmentId = payload;
  },
  /*Получение галлереи клиники*/
  [names.MUTATE_SAVE_DEPARTMENT_GALLERY]: (state, payload) => {
    state.objGallery = payload;
  },

  /*Сохраняет инфу о выбранном докторе*/
  [names.MUTATE_SAVE_DEPARTMENT_SELECTED_DOCTOR_INFO]: (state, payload) => {
    state.objSelectedDoctorInfo = payload;
  },

  [names.MUTATE_SAVE_OBJ_CLINIC_DEPARTMENTS]: (state, payload) => {
    state.objClinicDepartments = payload;
  },

  /*Сохраняет инфу о выбранном докторе*/
  [names.MUTATE_SAVE_DEPARTMENT_SELECTED_DOCTOR_SERVICES]: (state, payload) => {
    state.objSelectedDoctorServices = payload;
  },

  /*Сохраняет сообщение о результатах выполнения запроса*/
  [names.MUTATE_SAVE_DEPARTMENT_CARD_MESSAGE]: (state, payload) => {
    state.message = payload;
  },

  /*Очищает инфу о выбранном докторе*/
  [names.MUTATE_ClEAR_DEPARTMENT_DRAWERS_INFO]: (state) => {
    state.objSelectedDoctorInfo = [];
    state.objSelectedDoctorServices = [];
    state.afterDetailState = false;
  },

  /*Получение галлереи клиники*/
  [names.MUTATE_SAVE_DEPARTMENT_INFO]: (state, payload) => {
    state.objDepartmentInfo = payload;
  },

  /*Получение услуг клиники*/
  [names.MUTATE_GET_DEPARTMENT_SERVICES]: (state, payload) => {
    state.objDepartmentServices = payload.result;
    state.objDepartmentServicesSpecializations = payload.specialization;
  },

  /*Получение врачей клиники*/
  [names.MUTATE_GET_DEPARTMENT_DOCTORS]: (state, payload) => {
    state.objDepartmentDoctors = payload;
  },

  /*Получение врачей клиники*/
  [names.MUTATE_GET_DEPARTMENT_DOCTORS_SPECIALIZATION]: (state, payload) => {
    state.objDepartmentDoctorsSpecializations = payload;
  },

  [names.MUTATE_SAVE_DEPARTMENT_SERVICE_PAGINATION]: (state, payload) => {
    state.objServicesPagination = payload;
  },

};
export { mutations };
