/*Экшены*/
import * as names from "store/names";
import CardService from "@/services/CardService";
import SearchService from "@/services/SearchService";

const actions = {
  /* Сохраняет ID клиники*/
  async [names.ACTION_SAVE_DEPARTMENT_ID_CARD]({ commit }, payload) {
    commit(names.MUTATE_SAVE_DEPARTMENT_ID_CARD, payload);
  },

  /*Сохраняет инфу о выбранном докторе*/
  async [names.ACTION_SAVE_DEPARTMENT_SELECTED_DOCTOR_INFO](
    { commit },
    payload
  ) {
    commit(names.MUTATE_SAVE_DEPARTMENT_SELECTED_DOCTOR_INFO, payload);
  },

  /*Очищает инфу из шторки*/
  async [names.ACTION_ClEAR_DEPARTMENT_DRAWERS_INFO]({ commit }, payload) {
    commit(names.MUTATE_ClEAR_DEPARTMENT_DRAWERS_INFO, payload);
  },

  async [names.ACTION_SAVE_DEPARTMENT_SELECTED_DOCTOR_SERVICES](
    { commit },
    payload
  ) {
    let response = await SearchService.mainSearchDoctorDetail(payload);
    if (response.success) {
      commit(
        names.MUTATE_SAVE_DEPARTMENT_SELECTED_DOCTOR_SERVICES,
        response.data.services
      );
      return "success";
    } else {
      commit(names.MUTATE_SAVE_DEPARTMENT_CARD_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },

  async [names.ACTION_SAVE_OBJ_CLINIC_DEPARTMENTS]({ commit }, payload) {
    let response = await CardService.getClinicDepartments(payload);
    if (response.success) {
      commit(names.MUTATE_SAVE_OBJ_CLINIC_DEPARTMENTS, response.data);
      return "success";
    } else {
      commit(names.MUTATE_SAVE_DEPARTMENT_CARD_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },

  /* Получение галереи клиники*/
  async [names.ACTION_GET_DEPARTMENT_GALLERY]({ commit }, payload) {
    let response = await CardService.getDepartmentGallery(payload);
    if (response.success) {
      commit(names.MUTATE_SAVE_DEPARTMENT_GALLERY, response.data);
      return "success";
    } else {
      commit(names.MUTATE_SAVE_DEPARTMENT_GALLERY, []);
    }
  },

  /* Получение всех услуг клиники*/
  async [names.ACTION_GET_DEPARTMENT_SERVICES]({ commit }, payload) {
    let response = await CardService.getDepartmentServices(payload);
    if (response.success) {
      commit(names.MUTATE_GET_DEPARTMENT_SERVICES, response.data);
      commit(names.MUTATE_SAVE_DEPARTMENT_SERVICE_PAGINATION, response.data.pagination
      );
      return "success";
    } else {
      commit(names.MUTATE_GET_DEPARTMENT_SERVICES, []);
    }
  },

  /* Получение всех врачей клиники*/
  async [names.ACTION_GET_DEPARTMENT_DOCTORS]({ commit }, payload) {
    let response = await CardService.getDepartmentDoctors(payload);
    if (response.success) {
      commit(names.MUTATE_GET_DEPARTMENT_DOCTORS, response.data);
      return "success";
    } else {
      commit(names.MUTATE_GET_DEPARTMENT_DOCTORS, []);
    }
  },

  /* Получение специализаций клиники с врачами*/
  async [names.ACTION_GET_DEPARTMENT_DOCTORS_SPECIALIZATIONS](
    { commit },
    payload
  ) {
    let response = await CardService.getSpecializationWithDoctorsForDepartment(
      payload
    );
    if (response.success) {
      commit(names.MUTATE_GET_DEPARTMENT_DOCTORS_SPECIALIZATION, response.data);
      return "success";
    } else {
      commit(names.MUTATE_SAVE_DEPARTMENT_CARD_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },
};
export { actions };
