/*Мутации*/
import * as names from "store/names";

const mutations = {
  [names.MUTATE_SHOW_DRAWER]: (state, payload) => {
    state.activeState = payload.activeState;
    state.type = payload.type;
  },

  [names.MUTATE_TOGGLE_DRAWER]: (state, payload) => {
    state.activeState = payload.activeState;
  },

  /*Сохраняет инфу месте перехода в форму*/
  [names.MUTATE_SAVE_AFTER_DETAIL]: (state, payload) => {
    state.afterDetailState = payload;
  },
};
export { mutations };
