/*Геттеры*/
import * as names from "store/names";

const getters = {
  /*Информация об успехе запроса*/
  [names.ORDER_SUCCESS]: (state) => {
    return state.orderSuccess;
  },
  /*Сообщение о результатах выполнения запроса*/
  [names.ORDER_MESSAGE]: (state) => {
    return state.message;
  },

  /*Текущее состояние шторки*/
  [names.ORDER_MODAL_ACTIVE]: (state) => {
    return state.modalActive;
  },
};
export { getters };
