/*Мутации*/
import * as names from "store/names";

const mutations = {
  [names.MUTATE_SHOW_PRELOADER]: (state, payload) => {
    state.show = payload.show;
    state.color = payload.color;
    state.size = payload.size;
    state.opacity = payload.opacity;
  },
};
export { mutations };
