<template>
  <div>
    <div class="curtain" :class="{ active: activeState }">
      <div
        class="d-flex flex-row return-btn pl-5 pl-sm-1"
        v-if="stateAfterDetail && drawerType === 'Fast' && resultTemplate"
      >
        <v-icon class="mr-1">
          {{ icons.mdiArrowLeft }}
        </v-icon>
        <button @click="returnDetail" type="button">к результатам</button>
      </div>
      <div v-show="activeState" class="control-btn" @click="toggleDetail">
        <v-btn icon>
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </div>
      <div class="curtain__nav">
        <component :is="resultTemplate" v-if="resultTemplate" />
      </div>
    </div>

    <v-overlay fixed :value="activeState" z-index="99"></v-overlay>
  </div>
</template>

<script>
import * as names from "store/names";
import { mapGetters, mapActions } from "vuex";
import { mdiArrowLeft, mdiClose } from "@mdi/js";

export default {
  name: "SideDrawer",
  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "drawer/MUTATE_SHOW_DRAWER") {
        this.loader().then(() => {
          this.activeState = state.drawer.activeState;
          this.resultTemplate = () => this.loader();
        });
      }
      if (mutation.type === "drawer/MUTATE_TOGGLE_DRAWER") {
        this.activeState = false;
      }
    });
  },
  data: () => ({
    activeState: false,
    resultTemplate: null,
    icons: {
      mdiClose,
      mdiArrowLeft,
    },
  }),
  computed: {
    ...mapGetters({
      resultTemplateName: names.RESULT_TEMPLATE_NAME,
      drawerType: names.DRAWER_TYPE,
      searchData: names.SEARCH_DATA,
      stateAfterDetail: names.AFTER_DETAIL,
    }),

    loader() {
      if (!this.resultTemplateName) {
        return null;
      }
      return () =>
        import(
          `components/main-search/templates/drawers/${this.resultTemplateName}${this.drawerType}`
        );
    },
  },
  methods: {
    ...mapActions({
      clearObjDoctorDetail: names.ACTION_MAIN_SEARCH_DOCTOR_DETAIL_CLEAR,
      clearObjClinicDetail: names.ACTION_MAIN_SEARCH_CLINIC_DETAIL_CLEAR,
      clearObjServiceDetail: names.ACTION_MAIN_SEARCH_SERVICE_DETAIL_CLEAR,
      clearObjDepartmentDetail: names.ACTION_ClEAR_DEPARTMENT_DRAWERS_INFO,
      saveAfterDetailState: names.ACTION_SAVE_AFTER_DETAIL,
      changeEntityId: names.ACTION_TRANSMISSION_ENTITY_ID,
    }),
    async toggleDetail() {
      this.changeEntityId(this.searchData.id);
      this.activeState = false;
      document.querySelector("body").style.overflow = "visible";
      setTimeout(
        () => [
          this.clearObjDoctorDetail(),
          this.clearObjClinicDetail(),
          this.clearObjServiceDetail(),
          this.clearObjDepartmentDetail(),
          this.saveAfterDetailState(false),
        ],
        500
      );
    },
    returnDetail() {
      this.$drawer.showDrawer(true, "Detail");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~scss/helpers/helpers";

.return-btn {
  color: $deep-blue;
  position: absolute;
  padding: 10px 0;
  background: white;
  left: 100px;
  font-size: 20px;
  width: 100%;
  span {
    color: $deep-blue;
  }
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @include phone-only {
    position: fixed;
    width: 100vw;
    height: 60px;
    z-index: 151;
    top: 0;
    left: 2px;
    border: none;
    span {
      color: $deep-blue;
    }
  }
}

.curtain {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 650px;
  z-index: 100;
  height: 100vh;
  overflow: hidden;
  transform: translateX(calc(100% - 10px));
  transition: all 0.3s ease-out;
  color: black;
  @include tablet-down {
    max-width: 100%;
  }

  .control-btn {
    position: absolute;
    top: 40px;
    left: 3px;
    background: $light-color;
    width: 68px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    font-size: 18px;
    border: 5px solid #fff;
    border-right: none;

    &:hover {
      color: #009eff;
      cursor: pointer;
    }

    @include phone-only {
      position: fixed;
      width: 100vw;
      height: 60px;
      z-index: 150;
      padding-right: 5%;
      top: 0;
      left: 2px;
      border: none;
      justify-content: flex-end;
      background-color: white;
      border-radius: 0;
    }
  }

  &__header {
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-left: 40px;
    font-size: 3vh;
    height: 8vh;
  }

  &__nav {
    margin-left: 70px;
    background: $light-color;
    padding: 10px 20px;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: 0 0 5px 2px $grey50;

    @include tablet-up {
      padding: 40px 20px;
    }
  }
}

.active {
  transform: translateX(0);
  transition: all 0.5s ease-out;

  .curtain__nav {
    @include phone-only {
      margin-left: 0;
      padding-top: 60px;
    }
  }
}
</style>
