<template>
  <div class="footer-menu">
    <div class="footer__nav">
      <p class="footer__nav-title">Меню</p>
      <ul class="footer__nav-items left">
        <li>
          <router-link to="/"> Главная </router-link>
        </li>
<!--        <li>-->
<!--          <router-link to="/blog"> Блог </router-link>-->
<!--        </li>-->
        <li>
          <router-link to="/help"> Помощь </router-link>
        </li>
      </ul>
    </div>
    <div class="footer__nav">
        <p class="footer__nav-title">Клиентам</p>
        <ul class="footer__nav-items">
          <li>
            <router-link to="/about"> О нас </router-link>
          </li>
          <li>
            <router-link to="/clinic-landing"> Сотрудничество </router-link>
          </li>
          <li>
            <a href="https://lk.medveddoc.com/login">
              Личный кабинет для клиник
            </a>
          </li>
        </ul>
      <div>
        <p class="footer__nav-title">Пользователям</p>
        <ul class="footer__nav-items">
          <li>
            <a href="https://old.medveddoc.com/docs/personal_data_processing.pdf">
              Обработка персональных данных
            </a>
          </li>
          <li>
            <a href="https://old.medveddoc.com/docs/personal_data_protection.pdf">
              Положение о защите персональных данных
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterMenu",
};
</script>

<style lang="scss" scoped>
.footer-menu {
  display: flex;
  justify-content: space-around;
  @include phone-only{
    display: block;
  }
  .footer__nav {
    &-title {
      font-size: 18px;
      color: $primary-lighter-color;
      margin-bottom: 15px;
    }

    &-items {
      list-style-type: none;
      font-size: 14px;
      padding-left: 0px;

      li {
        color: $light-color;
        margin-bottom: 5px;

        a {
          @include link-light($light-color, $primary-lighter-color);
        }
      }
      &.left {
        display: flex;
        li {
          margin-right: 15px;
        }
      }
    }
  }
}
</style>
