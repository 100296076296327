/*Состояния*/
const state = () => ({
  /*Состояние модального окна для карты*/
  addressDialog: false,

  /*Состояние модального окна для карты с указанием адреса*/
  mapAddressDialog: false,

  /*Координаты указанные на карте*/
  mapAddressCoordinatesLat: null,
  mapAddressCoordinatesLong: null,

  /*Адрес указанный на карте*/
  mapAddress: "",

});
export { state };
