/*Мутации*/
import * as names from "store/names";

const mutations = {
  /*Сохраняет состояние модального окна для карты*/
  [names.MUTATE_SAVE_ADDRESS_DIALOG]: (state, payload) => {
    state.addressDialog = payload;
  },

  /*Сохраняет состояние модального окна для карты с указанием адреса*/
  [names.MUTATE_SAVE_MAP_ADDRESS_DIALOG]: (state, payload) => {
    state.mapAddressDialog = payload;
  },

  /*Сохраняет указанные для поиска координаты на карте*/
  [names.MUTATE_SAVE_MAP_COORDINATES]: (state, payload) => {
    state.mapAddressCoordinatesLat = payload.mapCoordinatesLat;
    state.mapAddressCoordinatesLong = payload.mapCoordinatesLong;
    state.mapAddress = payload.myAddress;
  },


};
export { mutations };
