/*Состояния*/
const state = () => ({
  /* id клиники */
  departmentId: {},

  message: "",

  objGallery: [],

  objDepartmentInfo: [],

  objDepartmentServices: [],

  objServicesPagination: [],

  objDepartmentDoctors: [],

  objSelectedDoctorInfo: [],

  objSelectedDoctorServices: [],

  objDepartmentServicesSpecializations: [],

  objDepartmentDoctorsSpecializations: [],

  objClinicDepartments: [],

});
export { state };
