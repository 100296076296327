import Api from "../services/service";

class SpecializationService {
  api = new Api();

  /*Получение популярных специализаций по ID города*/
  getPopularSpecialization(payload) {
    return this.api.get("popular-specialization/get", {
      cityId: payload.cityId,
    });
  }
}

export default new SpecializationService();
