/*Экшены*/
import * as names from "store/names";
import BasketService from "../../../services/BasketService";

const actions = {
  /*Получение статичной страницы*/
  async [names.ACTION_GET_BASKET]({ commit }) {
    let response = await BasketService.get();
    if (response.success) {
      commit(names.MUTATE_SAVE_OBJ_BASKET, response.data);
      return "success";
    } else {
      commit(names.MUTATE_SAVE_BASKET_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },

  /*Добавление в корзину*/
  async [names.ACTION_ADD_BASKET]({ commit }, payload) {
    let response = await BasketService.add(payload);
    if (response.success) {
      return "success";
    } else {
      commit(names.MUTATE_ADD_BASKET_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },
};
export { actions };
