/*Экшены*/
import * as names from "store/names";

const actions = {
  async [names.ACTION_TRANSMISSION_RESULT_TEMPLATE_NAME]({ commit }, payload) {
    commit(names.MUTATE_SAVE_RESULT_TEMPLATE_NAME, payload);
  },

  async [names.ACTION_TRANSMISSION_ENTITY_NAME]({ commit }, payload) {
    commit(names.MUTATE_SAVE_ENTITY_NAME, payload);
  },

  async [names.ACTION_TRANSMISSION_ENTITY_ID]({ commit }, payload) {
    commit(names.MUTATE_SAVE_ENTITY_ID, payload);
  },

  async [names.ACTION_SELECT_RESULT_TEMPLATE]({ commit }, payload) {
    commit(names.MUTATE_SAVE_RESULT_TEMPLATE_NAME, payload.templateName);
    commit(names.MUTATE_SAVE_ENTITY_ID, payload.data.id);
    commit(names.MUTATE_SAVE_ENTITY_NAME, payload.data.entity);
    commit(names.MUTATE_SELECT_RESULT_TEMPLATE, payload.data);
  },

  async [names.ACTION_CLEAR_RESULT_TEMPLATE]({ commit }, payload) {
    commit(names.MUTATE_SELECT_RESULT_TEMPLATE, payload);
  },
};
export { actions };
