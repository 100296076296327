/*Экшены*/
import * as names from "store/names";

const actions = {
  /*Очищает инфу из шторки*/
  async [names.ACTION_SAVE_AFTER_DETAIL]({ commit }, payload) {
    commit(names.MUTATE_SAVE_AFTER_DETAIL, payload);
  },
};
export { actions };
