/*Мутации*/
import * as names from "store/names";

const mutations = {
  /*Сохраняет объект со страницей*/
  [names.MUTATE_SAVE_OBJ_PAGE]: (state, payload) => {
    state.page = payload;
  },
  /*Сохраняет сообщение о результатах выполнения запроса*/
  [names.MUTATE_SAVE_PAGE_MESSAGE]: (state, payload) => {
    state.message = payload;
  },
};
export { mutations };
