import Vue from "vue";
import App from "./App.vue";
import VueGtm from "@gtm-support/vue2-gtm";
import { MediaQueries } from "vue-media-queries";
import VueYandexMetrika from "vue-yandex-metrika";
import VueGeolocation from "vue-browser-geolocation";
import vueBemCn from "vue-bem-cn";
import "./registerServiceWorker";
import notifier from "./plugins/notifier";
import drawer from "./plugins/drawer";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { VueMaskDirective } from "v-mask";
import fontsLoader from "./plugins/fontsLoader";
Vue.directive("mask", VueMaskDirective);

Vue.config.productionTip = false;
const mediaQueries = new MediaQueries();
Vue.use(mediaQueries);
Vue.use(VueGeolocation);

Vue.use(VueYandexMetrika, {
  id: 64731667,
  router: router,
  env: process.env.NODE_ENV,
});

Vue.use(VueGtm, {
  id: "GTM-PWL3X4S",
  defer: false,
  compatibility: false,
  nonce: "2726c7f26c",
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});

const bemConfig = {
  delimiters: {
    ns: "", // namespace
    el: "__", // element delimeter
    mod: "_", // modifier delimeter
    modVal: "_", // value delimeter for modifier
  },
};
Vue.use(vueBemCn, bemConfig);
Vue.use(notifier);
Vue.use(drawer);
Vue.use(fontsLoader);

new Vue({
  router,
  store,
  render: (h) => h(App),
  vuetify,
  mediaQueries,
}).$mount("#app");
