/*Мутации*/
import * as names from "store/names";

const mutations = {
  [names.MUTATE_SAVE_RESULT_TEMPLATE_NAME]: (state, payload) => {
    state.resultTemplateName = payload;
  },

  [names.MUTATE_SAVE_ENTITY_NAME]: (state, payload) => {
    state.entityName = payload;
  },

  [names.MUTATE_SAVE_ENTITY_ID]: (state, payload) => {
    state.entityId = payload;
  },

  [names.MUTATE_SELECT_RESULT_TEMPLATE]: (state, payload) => {
    state.searchData = payload;
  },
};
export { mutations };
