import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg",
  },
  /*Кастомная тема*/
  theme: {
    themes: {
      dark: {
        primary: "#6d84e4",
        secondary: "#00AACC",
        accent: "#00c2ff",
        error: "#cf6679",
        warning: "#ffc107",
        info: "#009688",
        success: "#4caf50",
        footer: "#575f73",
      },
      light: {
        primary: "#0044cc",
        toolbar: "#e3ecfc",
        secondary: "#00AACC",
        accent: "#00c2ff",
        error: "#b00020",
        warning: "#ffc107",
        info: "#009688",
        success: "#4caf50",
        footer: "#575f73",
      },
    },
  },
});
