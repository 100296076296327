/*Экшены*/
import * as names from "store/names";
import FeedbackService from "@/services/FeedbackService";

const actions = {
  /* Сохраняет объект с отзывами о враче*/
  async [names.ACTION_GET_OBJ_FEEDBACK]({ commit }, payload) {
    let response = await FeedbackService.getFeedback(payload);
    if (response.success) {
      commit(names.MUTATE_SAVE_OBJ_FEEDBACK, response.data,);
      return "success";
    } else {
      commit(names.MUTATE_SAVE_FEEDBACK_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },


  /* Отправляет отзыв*/
  async [names.ACTION_SEND_OBJ_FEEDBACK]({ commit }, payload) {
    let response = await FeedbackService.addFeedback(payload);
    if (response.success) {
      return "success";
    } else {
      commit(names.MUTATE_SAVE_FEEDBACK_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },
};
export { actions };
