import Api from "../services/service";
class OrderService {
  api = new Api();
  /*Отправка заявки*/
  fastOrder(payload) {
    return this.api.post("order/fast-order", {
      orderFio: payload.name,

      orderPhone: payload.phone,

      smsNotification: payload.smsNotification,

      noDifferenceOrder: payload.noDifferenceOrder,

      childOrder: payload.childCheck,

      selectOrderDay: payload.orderDay,

      selectOrderTime: payload.orderTime,

      selectOrderComment: payload.comment,

      service: payload.service,

      nameChild: payload.nameChild,

      dateChild: payload.dateChild,

    });
  }
}
export default new OrderService();
