/*Экшены*/
import * as names from "store/names";
import PagesService from "../../../services/PagesService";

const actions = {
  /*Получение статичной страницы*/
  async [names.ACTION_GET_PAGE]({ commit }, payload) {
    let response = await PagesService.get(payload);
    if (response.success) {
      commit(names.MUTATE_SAVE_OBJ_PAGE, response.data);
      return "success";
    } else {
      commit(names.MUTATE_SAVE_PAGE_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      return "error";
    }
  },
};
export { actions };
