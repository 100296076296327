<template>
  <v-snackbar v-model="show" :color="type" elevation="24" top>
    <div class="text-center">
      {{ message }}
    </div>
  </v-snackbar>
</template>

<script>
import useSound from "vue-use-sound";
import warningSound from "sounds/notification-warning.mp3";
import errorSound from "sounds/notification-error.mp3";
import successSound from "sounds/notification-success.mp3";

export default {
  data: () => ({
    show: false,
    message: "",
    type: "",
  }),
  setup() {
    const [warningCase] = useSound(warningSound, { volume: 0.25 });
    const [errorCase] = useSound(errorSound, { volume: 0.25 });
    const [successCase] = useSound(successSound, { volume: 0.25 });

    return {
      warningCase,
      errorCase,
      successCase,
    };
  },

  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === "snackbar/MUTATE_SHOW_MESSAGE") {
        this.message = state.snackbar.content;
        this.type = state.snackbar.type;
        this.show = true;
        if (state.snackbar.type === "warning") {
          this.warningCase();
        }
        if (state.snackbar.type === "error") {
          this.errorCase();
        }
        if (state.snackbar.type === "success") {
          this.successCase();
        }
      }
    });
  },
};
</script>
