/*Экшены*/
import * as names from "store/names";
import OrderService from "@/services/OrderService";

const actions = {
  /*Сохраняет инфу о текущем статусе модалки*/
  async [names.ACTION_SAVE_MODAL_ACTIVE]({ commit }, payload) {
    commit(names.MUTATE_SAVE_MODAL_ACTIVE, payload);
  },

  /*Отправление заявки*/
  async [names.ACTION_FAST_ORDER]({ commit }, payload) {
    let response = await OrderService.fastOrder(payload);
    if (response.success) {
      commit(names.MUTATE_SAVE_ORDER_SUCCESS, true);
      return "success";
    } else {
      commit(names.MUTATE_SAVE_ORDER_MESSAGE, {
        type: "error",
        message: response.error ? response.error.message : "Ошибка!",
      });
      commit(names.MUTATE_SAVE_ORDER_SUCCESS, false);
      return "error";
    }
  },
};
export { actions };
