import Vue from "vue";
import Vuex from "vuex";
import news from "store/modules/news/news";
import pages from "store/modules/pages/pages";
import search from "store/modules/search/search";
import city from "store/modules/city/city";
import snackbar from "./modules/snackbar/snackbar";
import connection from "store/modules/connection/connection";
import drawer from "store/modules/drawer/drawer";
import preloader from "store/modules/preloader/preloader";
import basket from "store/modules/basket/basket";
import order from "store/modules/order/order";
import templateLoading from "store/modules/templateLoading/templateLoading";
import doctorCard from "store/modules/doctorCard/doctorCard";
import departmentCard from "store/modules/departmentCard/departmentCard";
import feedback from "store/modules/feedback/feedback";
import map from "store/modules/map/map";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    news,
    pages,
    search,
    city,
    snackbar,
    drawer,
    basket,
    preloader,
    connection,
    templateLoading,
    order,
    doctorCard,
    departmentCard,
    feedback,
    map,
  },
});
