import { state } from "store/modules/drawer/states";
import { getters } from "store/modules/drawer/getters";
import { mutations } from "store/modules/drawer/mutations";
import { actions } from "store/modules/drawer/actions";
export default {
  state,
  getters,
  mutations,
  actions,
};
