/*Мутации*/
import * as names from "store/names";

const mutations = {
  /*Сохраняет ID врача*/
  [names.MUTATE_SAVE_DOCTOR_ID]: (state, payload) => {
    state.doctorId = payload;
  },

  /*Сохраняет объект с информацией о враче*/
  [names.MUTATE_SAVE_OBJ_DOCTOR_CARD]: (state, payload) => {
    state.objDoctor = payload;
    state.objDoctorSpecialization = payload.doctorSpecialization;
    state.objDoctorDepartment = payload.doctorClinics;
  },

  /*Сохраняет сообщение о результатах выполнения запроса*/
  [names.MUTATE_SAVE_DOCTOR_CARD_MESSAGE]: (state, payload) => {
    state.message = payload;
  },

  /*Сохраняет объект с подробным результатом услуг о докторе и краткой  информацией*/
  [names.MUTATE_SAVE_OBJ_DOCTOR_SERVICES]: (state, payload) => {
    state.objDoctorServices = payload.services;
  },

  [names.MUTATE_SAVE_DOCTOR_CARD_SERVICE_PAGINATION]: (state, payload) => {
    state.objDoctorCardServicePagination = payload;
  },
};
export { mutations };
